import React, { Component } from "react";
import "../../styles/Sidebar.scss";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
import ScheduleMeeting from "../ScheduleMeeting/ScheduleMeeting.component";
// import { bubble as Menu } from 'react-burger-menu'

class Sidebar extends Component {
    state = {
        navExpanded: false,
    };

    componentDidMount() {
        document.onkeydown = function (evt) {
            console.log(this.state.navExpanded);
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = evt.key === "Escape" || evt.key === "Esc";
            } else {
                isEscape = evt.keyCode === 27;
            }
            if (isEscape && this.state.navExpanded) {
                this.setState({ navExpanded: !this.state.navExpanded });
            }
        }.bind(this);
    }

    render() {
        return (
            <div className="sidebar">
                <button
                    className={this.state.navExpanded ? "menu opened" : "menu"}
                    onClick={() =>
                        this.setState({ navExpanded: !this.state.navExpanded })
                    }
                    aria-label="Main Menu"
                    aria-expanded={this.state.navExpanded}
                >
                    <svg viewBox="0 0 100 100">
                        <path
                            className="line line1"
                            style={
                                this.state.navExpanded
                                    ? { stroke: "black" }
                                    : { stroke: "white" }
                            }
                            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                        />
                        <path
                            className="line line2"
                            d="M 20,50 H 80"
                            style={
                                this.state.navExpanded
                                    ? { stroke: "black" }
                                    : { stroke: "white" }
                            }
                        />
                        <path
                            className="line line3"
                            style={
                                this.state.navExpanded
                                    ? { stroke: "black" }
                                    : { stroke: "white" }
                            }
                            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                        />
                    </svg>
                </button>
                <div
                    className={
                        this.state.navExpanded
                            ? "menu-panel active"
                            : "menu-panel hidden"
                    }
                >
                    <Link
                        to="/"
                        onClick={() =>
                            this.setState({
                                navExpanded: !this.state.navExpanded,
                            })
                        }
                    >
                        <Slide left cascade>
                            Home
                        </Slide>
                    </Link>
                    <Link
                        to="/projects"
                        onClick={() =>
                            this.setState({
                                navExpanded: !this.state.navExpanded,
                            })
                        }
                    >
                        <Slide right cascade>
                            Projects
                        </Slide>
                    </Link>
                    <Link
                        to="/resume"
                        onClick={() =>
                            this.setState({
                                navExpanded: !this.state.navExpanded,
                            })
                        }
                    >
                        <Slide left cascade>
                            Resume
                        </Slide>
                    </Link>

                    <Link
                    to="/blog"
                    onClick={() =>
                        this.setState({
                            navExpanded: !this.state.navExpanded,
                        })
                    }
                >
                    <Slide right cascade>
                        Blog
                    </Slide>
                    <ScheduleMeeting />
                </Link>
                </div>
                
            </div>
        );
    }
}

export default Sidebar;
