import React, { Component } from "react";
import "../../styles/Social.scss";
import { Link } from "react-router-dom";
import {
    IoLogoInstagram,
    IoLogoLinkedin,
    IoLogoGithub,
    IoMailOutline,
    IoPaperPlane,
} from "react-icons/io5";

import ReactTooltip from "react-tooltip";

class SocialRow extends Component {
    render() {
        return (
            <div className="SocialRow">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"></link>
                {/* <Link
                    target="_blank"
                    to={{
                        pathname: "https://www.instagram.com/anikethirpara/",
                    }}
                >
                    <IoLogoInstagram data-for="main" data-tip="Instagram" />
                </Link> */}
                <Link
                    target="_blank"
                    to={{
                        pathname: "https://www.linkedin.com/in/anikethirpara/",
                    }}
                >
                    <IoLogoLinkedin data-for="main" data-tip="LinkedIn" />
                </Link>
                <Link
                    target="_blank"
                    to={{ pathname: "https://github.com/gdg4dev" }}
                >
                    <IoLogoGithub data-for="main" data-tip="Github" />
                </Link>
                <Link
                    target="_blank"
                    to={{ pathname: "mailto:me@anikethirpara.com" }}
                >
                    <IoMailOutline data-for="main" data-tip="E-mail" />
                </Link>
                <Link
                    target="_blank"
                    to={{ pathname: "https://t.me/anikethirpara" }}
                >
                    <IoPaperPlane data-for="main" data-tip="Telegram" />
                </Link>
                <ReactTooltip id="main" type="light" effect="solid" />
            </div>
        );
    }
}

export default SocialRow;
