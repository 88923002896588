import React, { Component } from "react";
import projectsData from "./Projects.json";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactPlayer from "react-player/lazy";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "../../styles/Projects.scss";

import SwiperCore, { Pagination, Navigation } from "swiper/core";
SwiperCore.use([Pagination, Navigation]);

class Projects extends Component {
    // componentDidMount () {
    //     // determines how many rows we need with 3 cols.
    //     const rows = [...Array(Math.ceil(projectsData.length / 3))]
    //     // fillup cols in rows
    //     const projectRows = rows.map((row,i) => projectsData.slice(i * 3, i * 3 + 3))
    //     // map row as div row
    //     const projectsList = projectRows.map((row, i) => {
    //         <div> </div>
    //     })
    // }
    render() {
        return (
            <div id="projects" className="container mt-5">
                <div className="row">
                    {projectsData.map((v, i) => {
                        return (
                            <div
                                className="col-lg-4 col-md-6 col-sm-12 project projectCard"
                                key={i}
                            >
                                <div className="card bg-dark text-light ">
                                    <Swiper
                                        pagination={{ type: "fraction" }}
                                        navigation={true}
                                        effect={"slide"}
                                        className="mySwiper"
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    >
                                        {v.preview_video_available ? (
                                            <SwiperSlide>
                                            <span className='prj-info'><span className="prj-status bg-success">{v.status.toUpperCase()}</span>{v.maintained ? '' : <span className="prj-status bg-danger">DISCONTINUED</span>}</span>
                                                <ReactPlayer
                                                    url={v.preview_video}
                                                    muted={true}
                                                    stopOnUnmount={true}
                                                    height="100%"
                                                    width="100%"
                                                    playing={false}
                                                    loop={true}
                                                    controls={true}
                                                />
                                            </SwiperSlide>
                                        ) : (
                                            ""
                                        )}

                                        {v.preview_images.map(
                                            (image, index) => {
                                                var url = `url(${image})`
                                                url = url.replace(/"/g, "\"" )
                                                return (
                                                    <SwiperSlide key={index} zoom={true} >
                                                    <span className="prj-info"><span className="prj-status bg-success">{v.status.toUpperCase()}</span>{v.maintained ? '' : <span className="prj-status bg-danger">DISCONTINUED</span>}</span>
                                                        <img className="att2 card-img-top" alt="" src={image}></img>
                                                    </SwiperSlide>
                                                );
                                            }
                                        )}
                                    </Swiper>
                                    <div className="card-body">
                                        <h4 className="card-title">
                                           <span>
                                           {v.demo_available ? <a href={v.live_url} target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>{v.project_name} <span className="h6" >↗</span></a> :   v.project_name
                                            .split(" ")
                                            .map(
                                                (w) =>
                                                    w[0].toUpperCase() +
                                                    w
                                                        .substr(1)
                                                        .toLowerCase()
                                            )
                                            .join(" ")}
                                          
                                           </span> 
                                         
                                        </h4>
                                        <p className="card-text">
                                            <h6 className="smallH6 dflex-type">
                                               <span>{v.type}</span> <span></span>
                                            </h6>
                                        </p>
                                        <p className="card-text">
                                            <h6 className="smallH6">
                                                {v.technologies.map((v, i) => (
                                                    <span className="tech-span">
                                                        {v}{" "}
                                                    </span>
                                                ))}
                                            </h6>
                                        </p>
                                        <p className="card-text">
                                            <h6>{v.desc}</h6>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Projects;

//   Will be available soon <button className="btn btn-info">btn</button>
