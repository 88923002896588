import React from "react";
import "../../styles/404.scss";
import {Link} from "react-router-dom"
const Missing404 = () => {
    return (
        <div className="missing404">
            <div id="m404">404</div>
            <div id="m404-desc">this location never existed !</div>
            <div id="m404-desc2">Go <Link to="/">home</Link> dude !!</div>
        </div>
    );
};
export default Missing404;
