import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import "./styles/App.scss";
import HomePage from "./components/Home/home.component";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import SocialRow from "./components/Social/Social.component";
import Sidebar from "./components/Sidebar/Sidebar.component";
import Resume from "./components/Resume/Resume.component";
import Projects from "./components/Projects/Projects.component";
import Missing404 from "./components/404/404.component";
import BlogPage from "./components/Blog/Blog.component";
import ScheduleMeeting from './components/ScheduleMeeting/ScheduleMeeting.component';

function App() {
    return (
        <Router>
            
            <Sidebar></Sidebar>
            <Switch>
                <Route path="/projects" exact={true}>
                    <Projects />
                </Route>
                <Route path="/resume" exact={true}>
                    <Resume />
                </Route>
                <Route path="/blog" exact={true}>
                <BlogPage/>
            </Route>
                <Route path="/" exact={true}>
                    <HomePage> </HomePage>
                </Route>
                <Route path="*">
                    <Missing404 />
                </Route>
            </Switch>
            <ScheduleMeeting />
        </Router>
    );
}

export default App;
