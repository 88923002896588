import React from "react";
import { PopupWidget } from "react-calendly";

const ScheduleMeeting = () => {
	return (
		<div>
			<PopupWidget
				url="https://calendly.com/anikethirpara/15-min-chat"
				/*
				 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
				 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
				 */
				rootElement={document.getElementById("root")}
				text="Let's Talk!"
				textColor="#ffffff"
                style={{textTransform: "capitalize !important"}}
				color="#00a2ff"
			/>
		</div>
	);
};

export default ScheduleMeeting
