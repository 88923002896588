import React, { Component } from "react";
import "../../styles/home.scss";
import Typed from "typed.js";
import SocialRow from "../Social/Social.component";

class HomePage extends Component {
  
    componentDidMount() {
        this.typed = new Typed(this.el, {
            strings: [
                "FULL STACK DEVELOPER",
                "BLOCKCHAIN DEVELOPER",
                "VIDEO EDITOR",
            ],
            typeSpeed: 40,
            loop: true,
            showCursor: false,
        });
        this.typed.start();
    }

    componentWillUnmount() {
        this.typed.destroy();
    }

    render() {
        return (
            <div className="homeCmp"> 
            <div className="intro">
            
                <img
                    id="profileImg"
                    src="https://i.imgur.com/DdNWaOT.png"
                    alt="logo"
                />
                <div className="just-wrapper">
                    <div>
                        <h4 className="name">
                            <span className="small-txt">I'm&nbsp;</span>
                            <span>Aniket Hirpara</span>
                        </h4>
                    </div>
                    <div className="occup">
                        <small className="small-txt">a self-taught&nbsp;</small>
                        <span
                            id="occupTypes"
                            ref={(el) => {
                                this.el = el;
                            }}
                        ></span>
                        </div>
                        <small className="small-txt small-txt-2" style={window.innerWidth < 600 ? {alignSelf: "center"}: {alignSelf: "flex-start"}}><span>with <b>fast-learning</b> trait</span><span className="blink"><b>.</b></span></small>
                </div>
              </div>  
              <SocialRow/>
            </div>
        );
    }
}

export default HomePage;


// <div id="flip">
// <div>
//     <div>FULL STACK DEVELOPER</div>
// </div>
// <div>
//     <div>FLUTTER DEVELOPER</div>
// </div>
// <div>
//     <div>VIDEO EDITOR</div>
// </div>
// </div>