import React, { useState, useEffect } from "react";
import "../../styles/Resume.scss";
import { Link } from "react-router-dom";

function Resume() {
    const [resumeDetails, setResumeDetails] = useState({
        github: "",
        dropbox: "",
        firebase: ""
    });

    useEffect(() => {
        // Fetch resume details
        fetch("https://apis.anikethirpara.com/getresumedetails", {
            method: "POST"
        })
            .then((response) => response.json())
            .then((data) => setResumeDetails(data))
            .catch((error) => console.error("Error fetching resume details:", error));
    }, []);

    return (
        <div id="resumeCmp">
            <div id="placeholderForMenu">
                [last updated: JAN 2024] Download from mirror:{" "}
                <Link to={{ pathname: resumeDetails.dropbox }} target="_blank">
                    Dropbox
                </Link>
                <Link to={{ pathname: resumeDetails.dropbox }} target="_blank">
                    Firebase
                </Link>
            </div>
            <iframe
                style={{
                    width: window.innerWidth,
                    height: window.innerHeight,
                    overflow: "hidden",
                    position: "relative",
                    top: "3%",
                }}
                src={`${resumeDetails.dropbox.slice(0,-5)}&raw=1`}
                type="application/pdf"
                title="title"
            />
        </div>
    );
}

export default Resume;
